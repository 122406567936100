import { shouldIgnoreError } from './should-ignore-error';

import type { BlacklistItem, MatcherConfig } from './should-ignore-error';
import type { Event, EventHint } from '@sentry/types';

const SENTRY_ALERTS_BLACKLIST: BlacklistItem[] = [];

export const beforeSend = (event: Event, hint?: EventHint) => {
  const matcherConfig: MatcherConfig = {
    message: (hint?.originalException as Error)?.message || '',
    url: event.request?.url || '',
  };

  return shouldIgnoreError(SENTRY_ALERTS_BLACKLIST, matcherConfig) ? null : event;
};
