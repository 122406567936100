// eslint-disable-next-line no-restricted-imports
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { useEffect } from 'react';

const REPLAY_EXCLUDED_ORGANIZATIONS: string[] = [];
const REPLAY_EXCLUDED_EMAIL_DOMAINS: string[] = ['redocly.com'];

export const initializeReplay = (organizationId: string, email?: string) => {
  if (REPLAY_EXCLUDED_ORGANIZATIONS.includes(organizationId)) return;
  if (email && REPLAY_EXCLUDED_EMAIL_DOMAINS.includes(email.split('@')[1])) return;

  const client = Sentry.getCurrentHub().getClient();
  if (client?.getIntegration(Sentry.Replay)) return;

  client?.addIntegration?.(
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: true,
    }),
  );

  client?.addIntegration?.(
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  );
};

// eslint-disable-next-line no-restricted-imports
export * from '@sentry/react';
